import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';
import { IntroGuard } from './guards/intro.guard';
import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes, CanLoad } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule),
    canLoad: [IntroGuard, AutoLoginGuard]
  },
  {
    path: 'otp/:phone_number',
    loadChildren: () => import('./otp/otp.module').then(m => m.OtpPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'product/:categoryId/:sCategoryId/:tCId/:prodID/:serviceId/:flag/:tailor_id',
    loadChildren: () => import('./product/product.module').then(m => m.ProductPageModule),
  },
  {
    path: 'productdetail/:id/:attrId',
    loadChildren: () => import('./productdetail/productdetail.module').then(m => m.ProductdetailPageModule),
  },
  {
    path: 'checkout',
    loadChildren: () => import('./checkout/checkout.module').then(m => m.CheckoutPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'address',
    loadChildren: () => import('./address/address.module').then(m => m.AddressPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-address/:source',
    loadChildren: () => import('./add-address/add-address.module').then(m => m.AddAddressPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'edit-profile',
    loadChildren: () => import('./edit-profile/edit-profile.module').then(m => m.EditProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'intro',
    loadChildren: () => import('./intro/intro.module').then(m => m.IntroPageModule)
  },
  {
    path: 'tab1',
    loadChildren: () => import('./tab1/tab1.module').then(m => m.Tab1PageModule),
  },
  {
    path: 'tab2/:serviceID',
    loadChildren: () => import('./tab2/tab2.module').then(m => m.Tab2PageModule),
  },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchPageModule),
  },
  {
    path: 'tab3',
    loadChildren: () => import('./tab3/tab3.module').then(m => m.Tab3PageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'tab4',
    loadChildren: () => import('./tab4/tab4.module').then(m => m.Tab4PageModule),
    canLoad: [AuthGuard]
  },
  {
    // path: 'order/:flag',
    path: 'order',
    loadChildren: () => import('./order/order.module').then(m => m.OrderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'orderdetail/:id/:serviceId',
    loadChildren: () => import('./orderdetail/orderdetail.module').then(m => m.OrderdetailPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'boutique-list',
    loadChildren: () => import('./boutique-list/boutique-list.module').then(m => m.BoutiqueListPageModule)
  },
  {
    path: 'e-address/:id',
    loadChildren: () => import('./e-address/e-address.module').then(m => m.EAddressPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'order-response',
    loadChildren: () => import('./order-response/order-response.module').then(m => m.OrderResponsePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'order-response/:flag',
    loadChildren: () => import('./order-response/order-response.module').then(m => m.OrderResponsePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'no-internet',
    loadChildren: () => import('./no-internet/no-internet.module').then(m => m.NoInternetPageModule)
  },
  {
    path: 'tailor-list/:categoryId/:sCategoryId/:tCId/:flag',
    loadChildren: () => import('./tailor-list/tailor-list.module').then(m => m.TailorListPageModule)
  },
  {
    path: 'tdreturn-req/:id/:serviceId',
    loadChildren: () => import('./tdreturn-req/tdreturn-req.module').then(m => m.TDReturnReqPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'tsstyle-attriutes/:third_level_catory_id/:product_id',
    loadChildren: () => import('./tsstyle-attriutes/tsstyle-attriutes.module').then(m => m.TSStyleAttriutesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'trorder-status-modal',
    loadChildren: () => import('./trorder-status-modal/trorder-status-modal.module').then(m => m.TrorderStatusModalPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'orderdetail-quantity-list',
    loadChildren: () => import('./orderdetail-quantity-list/orderdetail-quantity-list.module').then(m => m.OrderdetailQuantityListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'trmeasurement-data/:headerID/:ProductId/:qty/:subsubcategoryId',
    loadChildren: () => import('./trmeasurement-data/trmeasurement-data.module').then(m => m.TrmeasurementDataPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'review-product',
    loadChildren: () => import('./review-product/review-product.module').then(m => m.ReviewProductPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'bout-sort-page',
    loadChildren: () => import('./bout-sort-page/bout-sort-page.module').then(m => m.BoutSortPagePageModule)
  },
  {
    path: 'sort-page',
    loadChildren: () => import('./sort-page/sort-page.module').then(m => m.SortPagePageModule)
  },
  {
    path: 'filter-page',
    loadChildren: () => import('./filter-page/filter-page.module').then(m => m.FilterPagePageModule)
  },
  {
    path: 'edit-styleattribute/:third_level_catory_id/:product_id/:cartId',
    loadChildren: () => import('./edit-styleattribute/edit-styleattribute.module').then(m => m.EditStyleattributePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'view-styleattribute/:third_level_catory_id/:product_id/:cartId',
    loadChildren: () => import('./view-styleattribute/view-styleattribute.module').then(m => m.ViewStyleattributePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'view-style-attribute-order/:serviceId/:orderId/:orderProductId',
    loadChildren: () => import('./view-style-attribute-order/view-style-attribute-order.module').then(m => m.ViewStyleAttributeOrderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'replace-policy/:id',
    loadChildren: () => import('./replace-policy/replace-policy.module').then(m => m.ReplacePolicyPageModule)
  },
  {
    path: 'design-query',
    loadChildren: () => import('./design-query/design-query.module').then(m => m.DesignQueryPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'design-query-list',
    loadChildren: () => import('./design-query-list/design-query-list.module').then(m => m.DesignQueryListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'biddings/:id',
    loadChildren: () => import('./biddings/biddings.module').then(m => m.BiddingsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'design-query-edit/:id',
    loadChildren: () => import('./design-query-edit/design-query-edit.module').then(m => m.DesignQueryEditPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'view-bidding/:id',
    loadChildren: () => import('./view-bidding/view-bidding.module').then(m => m.ViewBiddingPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'review-page/:productId/:serviceId',
    loadChildren: () => import('./review-page/review-page.module').then(m => m.ReviewPagePageModule)
  },
  {
    path: 'service-cancelpolicy',
    loadChildren: () => import('./service-cancelpolicy/service-cancelpolicy.module').then(m => m.ServiceCancelpolicyPageModule)
  },
  {
    path: 'other-orders',
    loadChildren: () => import('./other-orders/other-orders.module').then(m => m.OtherOrdersPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'modifyrefund-response',
    loadChildren: () => import('./modifyrefund-response/modifyrefund-response.module').then(m => m.ModifyrefundResponsePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'support-list',
    loadChildren: () => import('./support-list/support-list.module').then(m => m.SupportListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'support-add',
    loadChildren: () => import('./support-add/support-add.module').then(m => m.SupportAddPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'support-view/:id',
    loadChildren: () => import('./support-view/support-view.module').then(m => m.SupportViewPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'image-preview-modal',
    loadChildren: () => import('./image-preview-modal/image-preview-modal.module').then(m => m.ImagePreviewModalPageModule)
  },
  {
    path: 'wishlist-page',
    loadChildren: () => import('./wishlist-page/wishlist-page.module').then(m => m.WishlistPagePageModule),
    canLoad: [AuthGuard]
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }