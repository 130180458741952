/* Check below params before release */
export const gc_app_version = "V 3.1";  //change at time of release only
export const gc_refresh_time_ms = 1000;
export const gc_onesec_time_ms = 1000;
export const gc_sms_timeout_ms = 60000;
export const DEVICE_TYPE = 3;           //to be changed as per install device 1 - android, 2 - ios, 3 - Web

export const DEVICE_AND = 1;
export const DEVICE_IOS = 2;
export const DEVICE_WEB = 3;

export const APP_NAME = "MAZATA";
export const APP_DESCRIPTION = "Mazata - Clothing Services";

// export const BASEURL = "http://127.0.0.1:3001/api";
export const BASEURL = "https://be.dev.mazata.in/api";
// export const BASEURL = "https://be.qty.mazata.in/api";
// export const BASEURL = "https://be.app.mazata.in/api";

// export const serverPaymentURL = "http://localhost/mazata/api/payment/process-payment-in-dev.php";
export const serverPaymentURL = "https://mazata.in/api/payment/process-payment-in-dev.php";
// export const serverPaymentURL = "https://mazata.in/api/payment/process-payment-in.php";

/* Check above params before release */

export const defaultProfileUrl = "https://ui-avatars.com/api/?background=F98E1D&color=FFFFFF&name=M";

export const outOfStock = "https://ui-avatars.com/api/?background=F98E1D&color=FFFFFF&name=S";

//Push Notification DataTypes
export const gc_pndt_campaign = 1;
export const gc_pndt_order_status = 2;
export const gc_pndt_low_stock = 3;
export const gc_pndt_assignments = 4;
export const gc_pndt_customer_support = 5;
export const gc_pndt_new_order_confirm = 6;

//Tailoring Services
export const TAILORING_SERVICE_URL = "/service/tailoring-service";
export const GET_PRICE_URL = "/get-price";
export const GET_STORE_URL = "/get-stores";

//upload own design
export const UPLOAD_OWN_DESIGN_URL = "/upload-own-design";
export const GET_BIDDINGS = "/get-biddings";

export const URL_SLASH = "/";

export const TOKEN_KEY = 'x-auth-token';
export const FCM_TOKEN = 'fcm_token';
export const OLD_FCM_TOKEN = 'old_fcm_token';
export const USER_NAME = 'user_name';
export const USER_EMAIL = 'user_email';
export const USER_MOBILE = 'user_mobile';
export const USER_PROFILE = 'user_profile';
export const CART_REFRESHED = 'cart_refresh';
export const FILE_KEY = 'file_key';
export const CHECK_ORDER_ID = 'check_order_id';

//Button Type
export const gc_bt_back = 0;
export const gc_bt_menu = 1;
export const gc_bt_leftback = 2;

export const gc_bt_right_btn_hide = 0;
export const gc_bt_right_btn_srh = 1;
export const gc_bt_right_btn_cart = 2;

// Active status
export const gc_st_active = 1;
export const gc_st_blocked = 2;
export const gc_st_ordered = 3;

// Error Message
export const LAUNCH_MESSAGE = "We Are Launching This Service Soon...";

export const ALLSERVICES = 0;
export const TRENDINGDESIGN = 1;
export const OWNUPLOAD = 2;
export const BOUTIQUE = 3;
export const TAILORINGSERVICE = 4;
export const READYMADE = 5;

//Services Description 
export const gc_txt_all_servicess = "All Products";
export const gc_txt_trending_design = "Made 2 Measure";
export const gc_txt_upload_design = "Upload Own Design";
export const gc_txt_boutiques = "Boutiques";
export const gc_txt_tailor_services = "Tailoring Services";
export const gc_txt_readymades = "Accessories";

export const NO_T_NEAR = "No tailors found near by";

export const under_development = "Under Development";
export const profile_update_msg = "Due to Profile Update Login Again!";

export const check_color_collected = "I am fine to accept color suggestions and design alterations";
export const image_count_issue = "1 (or) upto 5 Images required";
export const refundCheckBoxTex = "Do you want Refund ?";
export const no_service_selected = "Select a service to search product";
export const no_service_text = "Enter keyword to search";

//App ID
export const gc_app_id_user = 1;

//Document types
export const gc_bdt_return_policy = 1;
export const gc_bdt_privacy_policy = 2;
export const gc_bdt_terms_policy = 3;

//show or hide slot in the 
export const gc_slot_view_show = 1;
export const gc_slot_view_hide = 0;

// screen Title
export const gc_scrn_title_uploadown_design = "Upload your Design";
export const gc_scrn_title_uploadown_design_edit = "Design Proposal(s)";
export const gc_scrn_title_uploaded_design = "Custom Design Uploads";
export const gc_scrn_title_VIEW_BID = "Proposal Details";
export const gc_scrn_title_biddings = "Biddings";
export const gc_scrn_view_biddings = "Biddings";
export const gc_scrn_view_reviews = "Reviews";
export const gc_scrn_view_cancelPol = "Cancellation Policy";

//Navigation Link IDS
export const gc_exit_app = 0;
export const gc_nli_home = 1;
export const gc_nli_profile = 2;
export const gc_nli_orders = 3;
export const gc_nli_checkout = 4;
export const gc_nli_login = 5;
export const gc_nli_logout = 6;
export const gc_nli_terms = 7;
export const gc_nli_privacy = 8;
export const gc_nli_return = 9;
export const gc_nli_address = 10;
export const gc_nli_share = 11;
export const gc_nli_td_list = 12;
export const gc_nli_ts_list = 13;
export const gc_nli_bout_list = 14;
export const gc_nli_rm_list = 15;
export const gc_nli_owd_list = 16;
export const gc_nli_otr_orders = 17;
export const gc_nli_support_list = 18;
export const gc_nli_wish_list = 19;

// export const gc_nli_store_loc = 3;

// export const gc_nli_ts_list = 7;
// export const gc_nli_td_order_list = 8;
// export const gc_nli_td_order_list_completed = 9;
// export const gc_nli_ts_order_list = 10;
// export const gc_nli_ts_order_list_completed = 11;
// export const gc_nli_product_list = 12;
// export const gc_nli_product_list_rm = 13;
// export const gc_nli_product_list_bt = 14;

export const gc_nli_goback = 999;
export const gc_nli_default = 1000;

//Campaign Hierarchy
export const gc_ch_service = 0;
export const gc_ch_category = 1;
export const gc_ch_sc_category = 2;
export const gc_ch_tc_category = 3;
export const gc_ch_product = 4;

//Share Text & Title
export const gc_stt_text = '"Redefining Custom Made Clothings & Apparels" - One stop shop for all your tailoring needs at your doorstep. Get your favorite dresses and designs stitched at the convenience of your fingertips. The end to end stitching process is completely handled by us';
export const gc_stt_title = "Try Mazata";
export const gc_stt_url = 'http://mazata.in/';
export const gc_stt_dialogTxt = 'Share with buddies';

//TR Orderstatus New One
export const gc_tdn_os_measurement_unassign = 0;
export const gc_tdn_os_measurement_assign = 1;
export const gc_tdn_os_measurement_completed = 2;
export const gc_tdn_os_order_accepted_TD = 3;
export const gc_tdn_os_ready_for_delivery = 4;
export const gc_tdn_os_assigned_collection = 5;
export const gc_tdn_os_measurement_order_collected = 6;
export const gc_tdn_os_measurement_order_home = 7;
export const gc_tdn_os_measurement_order_in_hub = 8;
export const gc_tdn_os_measurement_order_submittted_in_hub = 9;
export const gc_tdn_os_unconfirmed = 999;
export const gc_tdn_os_cancelled = 1000;

export const gc_invoice_show = 1;
export const gc_invoice_hide = 0;

//TR Orderstatus description
export const gc_tdn_os_Order_Placed = "Order Placed";
export const gc_tdn_os_Measurement_Collected = "Measurement Collected";
export const gc_tdn_os_Order_Accepted = "Order Accepted";
export const gc_tdn_os_Pickup_Initiated = "Pickup Initiated";
export const gc_tdn_os_Order_Completed = "Order Completed";
export const gc_tdn_os_OrdertakentoHub = "Order taken to Hub";
export const gc_tdn_os_Processing = "Processing....";
export const gc_tdn_os_order_cancel = "Order Cancelled";
export const gc_tdn_os_query_canceled = "Query Cancelled";

//Calling window
export const address_list = 0;
export const checkout_list_1 = 1;
export const checkout_list_2 = 2;
export const tailoring_service = 3;

// Discount
export const coupon_percentage = 1;
export const coupon_flat = 2;

// query status
export const gc_qr_created = 0;
export const gc_qr_open = 1;
export const gc_qr_inprogress = 2;
export const gc_qr_resolved = 3;

export const gc_qr_created_txt = "Created";
export const gc_qr_open_txt = "Open";
export const gc_qr_inprogress_txt = "In Progress";
export const gc_qr_resolved_txt = "Resolved";

// Tailoring services redirection flag
export const gc_sa_cart = 1;
export const gc_sa_list = 2;

// reference Table Image Type
export const gc_ir_variant_image = 1;  //Products
export const gc_ir_campaign_image = 2; //Measurement image
export const gc_ir_UOD_order = 3;      //Upload Own Design
export const gc_ir_variant_image_tailoringService = 4; // tailoring service image

// user types
export const gc_ut_site_admin = 1; // Site Admin
export const gc_ut_zone_admin = 2; // Zone Admin
export const gc_ut_customers = 5;  // Customers

// Tax Margin
export const gc_tm_less_than_or_equal = 1;
export const gc_tm_great_than = 2;

//GPS Options
export const gc_gps_options = {
    timeout: 10000,
    enableHighAccuracy: false,
    maximumAge: 6000
};

//Default co-ordinates
export const gc_dc_lat = 13.067439;
export const gc_dc_long = 80.237617;

//to be collected
export const gc_tbc_na = 0; //Nothing
export const gc_tbc_measurement = 1; //Measurement
export const gc_tbc_samplecloth = 2; //Sample cloth
export const gc_tbc_orderData = ["NA", "Measurement", "Sample Cloth"];

//inside zone
export const gc_izone_no = 0; //Out of Zone
export const gc_izone_yes = 1; //Within Zone
export const gc_izone_option = ["Out of Service Area", "Inside Service Area"]; //Nothing